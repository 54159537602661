import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import GlobalStyle from "../globals.module.scss"

const Index = () => {
  /**
   * ql_index: Contains landing page content
   */
  const ql_index = useStaticQuery(graphql`
    query {
      # List of events displayed on the left side of the page
      allContentfulLandingPageContent {
        edges {
          node {
            title
            image {
              file {
                url # Image URL
              }
            }
            url # Lnk to content
            size # Size of content
            leftOffset # Left offset
            topOffset # Top offset
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      {ql_index.allContentfulLandingPageContent.edges.map((content, id) => {
        return (
          <a
            href={content.node.url}
            target="_blank"
            rel="noopener noreferrer"
            key={id}
          >
            <div
              className={GlobalStyle.landingPageContent}
              style={{
                top: content.node.topOffset + "vh",
                left: content.node.leftOffset + "vw",
                width: content.node.size + "vh",
              }}
            >
              <img
                src={content.node.image.file.url}
                alt={content.node.title}
                width="100%"
                height="auto"
              />
            </div>
          </a>
        )
      })}
    </Layout>
  )
}

export default Index
